<template>
  <div
    id="app-mob-nav"
    :class="{ active: isOpenBar }"
  >
    <div class="top-bar">
      <router-link to="/">
        <div class="logo">
          <img
            src="@/assets/image/logo_white.png"
            alt="LOGO"
            draggable="false"
          />
        </div>
      </router-link>
      <div
        class="menu-opener"
        @click="isOpenBar = !isOpenBar"
      >
        <i
          class="fa fa-bars"
          v-if="!isOpenBar"
        ></i>
        <i
          class="fa fa-times"
          v-if="isOpenBar"
        ></i>
      </div>
    </div>
    <div
      class="menu"
      v-if="isOpenBar"
    >
      <template v-if="clientData.id">
        <transition-group
          name="slide-left"
          tag="ul"
          class="nav"
          appear
        >
          <li
            v-for="item in filteredNavItems"
            :key="item.id"
          >
            <router-link
              :to="{ name: item.route, params: { client_id: clientData.id } }"
              @click.native="clickTopNavLink(item)"
            >
              <span>{{ item.label }}</span>
              <i
                class="fa fa-sort-down"
                :class="{ active: isToggledMiniBar }"
                v-if="filteredChildren(item.children).length"
              ></i>
            </router-link>
            <div
              class="dropdown"
              v-if="
                  filteredChildren(item.children).length && isToggledMiniBar
                "
            >
              <li
                  v-for="(child, index) in filteredChildren(item.children)"
                  :key="index"
                >
                  <router-link
                    :to="{
                      name: child.route,
                      params: { client_id: clientData.id },
                    }"
                    @click.native="isOpenBar = false"
                  >
                    <span>{{ child.label }}</span>
                  </router-link>
                </li>
            </div>
          </li>
        </transition-group>
      </template>
      <div class="bottom">
        <template v-if="userData.usr_id">
          <router-link
            :to="{
              name: 'NotificationView',
              params: { client_id: clientData.id },
            }"
            v-if="clientData.id && checkHasPermission('view-messages')"
            @click.native="isOpenBar = false"
          >
            <button>
              <span>
                <span class="fa fa-bell"></span>
                <span v-if="getNotificationCount">{{
                  getNotificationCount
                }}</span>
              </span>
            </button>
          </router-link>
          <router-link
            :to="{ name: 'ProfileView' }"
            @click.native="isOpenBar = false"
          >
            <button>
              <span>
                <span class="fa fa-user"></span>
                <span>{{ getProfileName }}</span>
              </span>
            </button>
          </router-link>
          <div class="auth">
            <button @click="logoutFunc">
              <span>
                <i class="fa fa-sign-out-alt"></i>
                <span>{{ $t('auth.logout') }}</span>
              </span>
            </button>
          </div>
        </template>
        <lang-changer />
      </div>
    </div>
    <transition name="fade">
      <div
        class="mob-overlay"
        @click="isOpenBar = false"
        v-if="isOpenBar"
      ></div>
    </transition>
  </div>
</template>

<script>
import LangChanger from '@/components/Shared/LangChanger/LangChanger.vue';
import navMixin from './navMixin.js';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    LangChanger,
  },
  mixins: [navMixin],
  data () {
    return {
      isOpenBar: false,
      isToggledMiniBar: false,
    };
  },
  computed: {
    ...mapGetters({
      filteredNavItems: 'filteredAppNavItems',
    }),
  },
  methods: {
    ...mapActions({
      logoutApi: 'logoutApi',
    }),
    async logoutFunc () {
      this.isOpenBar = false;

      const logoutLogic = () => {
        const currComp = this.$route.name;
        if (currComp !== 'LoginPage') {
          try {
            this.logoutApi(localStorage.sid);
            localStorage.clear();
            this.$router.replace({
              name: 'LoginPage',
            });
          } catch (err) {
            this.errorHelperMixin.parsePrintErrorMsg(err);
          }
        }
      };

      logoutLogic();
    },
    filteredChildren (children = []) {
      return children.filter(item => !item.permissions || this.checkHasPermission(item.permissions));
    },
    clickTopNavLink (item) {
      if (this.filteredChildren(item.children).length) {
        this.isToggledMiniBar = !this.isToggledMiniBar;
      } else {
        this.isOpenBar = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app-mob-nav {
  @include mob-nav-bar;
}
</style>
