<template>
  <multiselect-view
    :handleProps="langProps"
    :handleEvents="langEvents"
  ></multiselect-view>
</template>

<script>
import i18n from '@/i18n.js';
import { mapActions, mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      appLangSelected: state => state.appLangSelected,
      langOptions: state => state.langOptions,
    }),
    langProps () {
      return {
        options: this.langOptions,
        placeholder: '',
        title: this.$i18n.t('selection.language'),
        value: this.langOptions.find(lang => lang.lang === this.appLangSelected.lang),
        allowEmpty: false,
        searchable: true,
        label: 'label',
      };
    },
    langEvents () {
      return {
        select: (evt) => {
          this.setLangFunc(evt);
        },
        remove: () => {
          // This shouldn't happen
          this.removeLangFunc();
        },
      };
    },
  },
  watch: {
    userData: {
      handler (val) {
        this.setupGetLangFunc();
      },
      deep: true,
    },
  },
  mounted () {
    this.setupGetLangFunc();
  },
  methods: {
    ...mapActions({
      saveLangStore: 'saveLangStore',
      patchConfig: 'patchConfig',
    }),
    setLangFunc (evt = {}) {
      // Early exit
      if (!evt.lang) {
        return;
      }

      this.saveLangStore({ lang: evt.lang });
      localStorage.lang = evt.lang;
      const isHomepage = this.$route.name === 'HomePage';
      const isAuthPage = this.$route.matched.some(rt => rt.name === 'AuthPage');
      if (!isHomepage && !isAuthPage && localStorage.sid) {
        this.changeLangConfig(evt.lang);
      }
      i18n.locale = evt.lang;
      if (this.$route.params.lang !== evt.lang) {
        this.$router.replace({
          params: {
            lang: evt.lang || 'en',
          },
        });
      }
    },
    removeLangFunc () {
      this.saveLangStore({ lang: null });
      localStorage.removeItem('lang');
    },
    setupGetLangFunc () {
      const findLang = (inputLang) => this.langOptions.find(lang => lang.lang === inputLang);
      let langToSet = null;

      try {
        const userJson = this.userData.usr_json;
        if (localStorage.lang) {
          // From Storage
          langToSet = findLang(localStorage.lang);
        } else if (userJson?.lang) {
          // From DB
          langToSet = findLang(userJson.lang);
        }
      } catch (err) {
        console.log(err.message);
      }

      if (langToSet) {
        // Sets default if nothing else is found
        this.setLangFunc(langToSet);
      }
    },
    async changeLangConfig (lang) {
      const params = { key: 'lang', value: lang };
      try {
        const res = await this.patchConfig(params);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.multiselect-view {
  $size: 80px;
  max-width: $size;
  width: $size;
  background: transparent;
  box-shadow: unset;

  ::v-deep input {
    background: transparent;
  }
}
</style>
