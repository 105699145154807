export default {
  computed: {
    getProfileName () {
      const defMsg = this.$i18n.t('app.profile');
      try {
        const user = this.userData;
        const fullName = `${user.usr_first_name || ''} ${user.usr_last_name || ''}`;
        return fullName.trim() || defMsg;
      } catch (err) {
        return defMsg;
      }
    },
  },
};
