var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ active: _vm.isOpenBar },attrs:{"id":"app-mob-nav"}},[_c('div',{staticClass:"top-bar"},[_c('router-link',{attrs:{"to":"/"}},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/image/logo_white.png"),"alt":"LOGO","draggable":"false"}})])]),_c('div',{staticClass:"menu-opener",on:{"click":function($event){_vm.isOpenBar = !_vm.isOpenBar}}},[(!_vm.isOpenBar)?_c('i',{staticClass:"fa fa-bars"}):_vm._e(),(_vm.isOpenBar)?_c('i',{staticClass:"fa fa-times"}):_vm._e()])],1),(_vm.isOpenBar)?_c('div',{staticClass:"menu"},[(_vm.clientData.id)?[_c('transition-group',{staticClass:"nav",attrs:{"name":"slide-left","tag":"ul","appear":""}},_vm._l((_vm.filteredNavItems),function(item){return _c('li',{key:item.id},[_c('router-link',{attrs:{"to":{ name: item.route, params: { client_id: _vm.clientData.id } }},nativeOn:{"click":function($event){return _vm.clickTopNavLink(item)}}},[_c('span',[_vm._v(_vm._s(item.label))]),(_vm.filteredChildren(item.children).length)?_c('i',{staticClass:"fa fa-sort-down",class:{ active: _vm.isToggledMiniBar }}):_vm._e()]),(
                _vm.filteredChildren(item.children).length && _vm.isToggledMiniBar
              )?_c('div',{staticClass:"dropdown"},_vm._l((_vm.filteredChildren(item.children)),function(child,index){return _c('li',{key:index},[_c('router-link',{attrs:{"to":{
                    name: child.route,
                    params: { client_id: _vm.clientData.id },
                  }},nativeOn:{"click":function($event){_vm.isOpenBar = false}}},[_c('span',[_vm._v(_vm._s(child.label))])])],1)}),0):_vm._e()],1)}),0)]:_vm._e(),_c('div',{staticClass:"bottom"},[(_vm.userData.usr_id)?[(_vm.clientData.id && _vm.checkHasPermission('view-messages'))?_c('router-link',{attrs:{"to":{
            name: 'NotificationView',
            params: { client_id: _vm.clientData.id },
          }},nativeOn:{"click":function($event){_vm.isOpenBar = false}}},[_c('button',[_c('span',[_c('span',{staticClass:"fa fa-bell"}),(_vm.getNotificationCount)?_c('span',[_vm._v(_vm._s(_vm.getNotificationCount))]):_vm._e()])])]):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'ProfileView' }},nativeOn:{"click":function($event){_vm.isOpenBar = false}}},[_c('button',[_c('span',[_c('span',{staticClass:"fa fa-user"}),_c('span',[_vm._v(_vm._s(_vm.getProfileName))])])])]),_c('div',{staticClass:"auth"},[_c('button',{on:{"click":_vm.logoutFunc}},[_c('span',[_c('i',{staticClass:"fa fa-sign-out-alt"}),_c('span',[_vm._v(_vm._s(_vm.$t('auth.logout')))])])])])]:_vm._e(),_c('lang-changer')],2)],2):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.isOpenBar)?_c('div',{staticClass:"mob-overlay",on:{"click":function($event){_vm.isOpenBar = false}}}):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }