<template>
  <div id="app-navbar">
    <router-link to="/">
      <div class="logo">
        <img src="@/assets/image/logo.png" alt="LOGO" draggable="false" />
      </div>
    </router-link>
    <ul class="nav" v-if="clientData.id">
      <li v-for="item in filteredNavItems" :key="item.id">
        <router-link
          :to="{ name: item.route, params: { client_id: clientData.id } }"
        >
          <span>{{ item.label }}</span>
        </router-link>
        <div class="dropdown" v-if="filteredChildren(item.children)">
          <li
            v-for="(child, index) in filteredChildren(item.children)"
            :key="index"
          >
            <router-link
              :to="{ name: child.route, params: { client_id: clientData.id } }"
            >
              <span>{{ child.label }}</span>
            </router-link>
          </li>
        </div>
      </li>
    </ul>
    <div class="right">
      <template v-if="userData.usr_id">
        <router-link
          :to="{
            name: 'NotificationView',
            params: { client_id: clientData.id },
          }"
          v-if="clientData.id && checkHasPermission('view-messages')"
        >
          <button class="trans">
            <span>
              <span class="fa fa-bell"></span>
              <span v-if="getNotificationCount">{{
                getNotificationCount
              }}</span>
            </span>
          </button>
        </router-link>
        <router-link :to="{ name: 'ProfileView' }">
          <button class="trans">
            <span>
              <span class="fa fa-user"></span>
              <span>{{ getProfileName }}</span>
            </span>
          </button>
        </router-link>
        <button class="trans" @click="logoutFunc">
          <span>
            <i class="fa fa-sign-out-alt"></i>
            <span>{{ $t('auth.logout') }}</span>
          </span>
        </button>
      </template>
      <lang-changer />
    </div>
  </div>
</template>

<script>
import LangChanger from '@/components/Shared/LangChanger/LangChanger.vue';
import navMixin from './navMixin.js';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    LangChanger,
  },
  mixins: [navMixin],
  computed: {
    ...mapGetters({
      filteredNavItems: 'filteredAppNavItems',
    }),
  },
  methods: {
    ...mapActions({
      logoutApi: 'logoutApi',
    }),
    async logoutFunc () {
      const logoutLogic = () => {
        const currComp = this.$route.name;
        if (currComp !== 'LoginPage') {
          try {
            this.logoutApi(localStorage.sid);
            localStorage.clear();
            this.$router.replace({
              name: 'LoginPage',
            });
          } catch (err) {
            this.errorHelperMixin.parsePrintErrorMsg(err);
          }
        }
      };

      logoutLogic();
    },
    filteredChildren (children = []) {
      return children.filter(item => !item.permissions || this.checkHasPermission(item.permissions));
    },
  },
};
</script>

<style lang="scss" scoped>
#app-navbar {
  @include full-nav-bar;
}
</style>
